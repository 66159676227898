"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SameSiteType = exports.deleteCookie = exports.getCookie = exports.setCookie = void 0;
function setCookie(name, val, expirationTime, sameSiteType, secure) {
    var date = new Date();
    var value = val;
    // expires in <expTime> hours
    date.setTime(date.getTime() + expirationTime * 60 * 60 * 1000);
    var cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    if (sameSiteType) {
        cookie += '; SameSite=' + sameSiteType.valueOf();
        if (sameSiteType === SameSiteType.NONE) {
            secure = true;
        }
    }
    if (secure) {
        cookie += '; Secure';
    }
    // Set it
    document.cookie = cookie;
}
exports.setCookie = setCookie;
function getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2) {
        return parts.pop().split(';').shift();
    }
}
exports.getCookie = getCookie;
function deleteCookie(name) {
    var date = new Date();
    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    // Set it
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
}
exports.deleteCookie = deleteCookie;
var SameSiteType;
(function (SameSiteType) {
    SameSiteType["STRICT"] = "Strict";
    SameSiteType["NONE"] = "None";
    SameSiteType["LAX"] = "Lax";
})(SameSiteType = exports.SameSiteType || (exports.SameSiteType = {}));
