"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cookie_utils_1 = require("../../clientlib-utils/ts/cookie-utils");
var url_utils_1 = require("../../clientlib-utils/ts/url-utils");
document.addEventListener('DOMContentLoaded', function () {
    console.debug('INFO external-identfier.bundle.js loaded');
    function setExternalIdentifiers() {
        // getting the CampaignId from the Url parameters and writing it cookie
        var urlParam = (0, url_utils_1.getUrlParameter)('traci_s');
        if (urlParam) {
            (0, cookie_utils_1.setCookie)('externalIdentifier', urlParam, 2, cookie_utils_1.SameSiteType.STRICT, true);
        }
    }
    setExternalIdentifiers();
});
